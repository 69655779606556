import React, { useState, useMemo, forwardRef, ForwardRefRenderFunction } from 'react';
import styles from './MissingItemForm.module.css';
import { MissingItem } from '../api/platform/integrations/salesforce/types/missing';
import { useData } from '../contexts/DataContext';
import { Product } from '../api/platform/integrations/salesforce/product/getActiveProducts';
import { sortSizes } from '../api/platform/integrations/salesforce/product/getActiveProducts';
import { logger } from '@/utils/logger';

interface Props {
  onChange: (items: MissingItem[]) => void;
  error?: string;
  onValidationChange?: (isValid: boolean) => void;
}

interface MissingFormRef {
  validateForm: () => boolean;
}

interface ProductWithSizes {
  name: string;
  sizes: string[];
  products: Product[];
}

const MissingItemForm: ForwardRefRenderFunction<MissingFormRef, Props> = (
  { onChange, error, onValidationChange }, 
  ref
) => {
  const { products } = useData();
  const [selectedProductName, setSelectedProductName] = useState<string>('');
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [items, setItems] = useState<MissingItem[]>([]);
  const [currentItem, setCurrentItem] = useState<Partial<MissingItem>>({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showCheckboxWarning, setShowCheckboxWarning] = useState(false);

  // Group products by name and collect available sizes
  const productGroups = useMemo(() => {
    const groups = new Map<string, ProductWithSizes>();
    
    products.forEach(product => {
      if (!groups.has(product.name)) {
        groups.set(product.name, {
          name: product.name,
          sizes: [],
          products: []
        });
      }
      
      const group = groups.get(product.name)!;
      if (!group.sizes.includes(product.size)) {
        group.sizes.push(product.size);
      }
      group.products.push(product);
    });

    groups.forEach(group => {
      group.sizes = sortSizes(group.sizes);
    });

    return Array.from(groups.values());
  }, [products]);

  const availableSizes = useMemo(() => {
    const group = productGroups.find(g => g.name === selectedProductName);
    return group?.sizes || [];
  }, [selectedProductName, productGroups]);

  const getProductBySizeAndName = (name: string, size: string): Product | undefined => {
    const group = productGroups.find(g => g.name === name);
    return group?.products.find(p => p.size === size);
  };

  const handleProductChange = (productName: string) => {
    setSelectedProductName(productName);
    setSelectedSize('');
    setCurrentItem({
      ...currentItem,
      productName,
      size: '',
      sku: ''
    });
  };

  const handleSizeChange = (size: string) => {
    const product = getProductBySizeAndName(selectedProductName, size);
    setSelectedSize(size);
    if (product) {
      setCurrentItem({
        ...currentItem,
        size,
        sku: product.sku
      });
    }
  };

  const handleQuantityChange = (quantity: string) => {
    const numValue = parseInt(quantity);
    if (quantity === '' || (Number.isInteger(numValue) && numValue > 0)) {
      setCurrentItem({
        ...currentItem,
        quantity: numValue
      });
    }
  };

  const handleAddItem = () => {
    if (isItemValid()) {
      const newItem = {
        productName: currentItem.productName!,
        sku: currentItem.sku!,
        size: currentItem.size!,
        quantity: currentItem.quantity!,
        additionalInformation: currentItem.additionalInformation || ''
      } as MissingItem;

      const newItems = [...items, newItem];
      setItems(newItems);
      setCurrentItem({});
      setSelectedProductName('');
      setSelectedSize('');
      
      onChange(newItems);
    }
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    onChange(newItems);
  };

  const isItemValid = () => {
    return currentItem.productName &&
           currentItem.size &&
           currentItem.quantity &&
           currentItem.quantity > 0;
  };

  const isFormValid = () => {
    return items.length > 0 && termsAccepted;
  };

  // Update validation whenever relevant state changes
  React.useEffect(() => {
    const isValid = isFormValid();
    onValidationChange?.(isValid);
    
    if (termsAccepted) {
      setShowCheckboxWarning(false);
    }
  }, [items.length, termsAccepted, onValidationChange]);

  const validateForm = () => {
    if (!termsAccepted) {
      setShowCheckboxWarning(true);
    }
    return isFormValid();
  };

  React.useImperativeHandle(ref, () => ({
    validateForm
  }));

  // Also add this effect to update parent when additionalInfo changes
  React.useEffect(() => {
    onChange(items);
  }, [items, onChange]);

  return (
    <div className={styles.missingForm}>
      <div className={styles.formFields}>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Body Style</label>
          <select
            value={selectedProductName}
            onChange={(e) => handleProductChange(e.target.value)}
            className={styles.select}
          >
            <option value="">Select Product</option>
            {productGroups.map(group => (
              <option key={group.name} value={group.name}>
                {group.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Size</label>
          <select
            value={selectedSize}
            onChange={(e) => handleSizeChange(e.target.value)}
            className={styles.select}
            disabled={!selectedProductName}
          >
            <option value="">Select Size</option>
            {availableSizes.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Quantity</label>
          <input
            type="number"
            min="1"
            value={currentItem.quantity || ''}
            onChange={(e) => handleQuantityChange(e.target.value)}
            className={styles.input}
            disabled={!selectedSize}
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Additional Information</label>
          <textarea
            value={currentItem.additionalInformation || ''}
            onChange={(e) => setCurrentItem({
              ...currentItem,
              additionalInformation: e.target.value
            })}
            className={styles.textarea}
            placeholder="Enter any additional information about this missing item"
            disabled={!selectedSize}
          />
        </div>

        <button 
          onClick={handleAddItem}
          disabled={!isItemValid()}
          className={styles.addButton}
          type="button"
        >
          Add Item
        </button>
      </div>

      {items.length > 0 && (
        <div className={styles.itemsTable}>
          <h3>Added Items ({items.length})</h3>
          <table>
            <thead>
              <tr>
                <th>Body Style</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.productName}</td>
                  <td>{item.size}</td>
                  <td>{item.quantity}</td>
                  <td>
                    <button
                      onClick={() => handleRemoveItem(index)}
                      className={styles.removeButton}
                      type="button"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className={styles.terms}>
        <label className={styles.checkbox}>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => {
              setTermsAccepted(e.target.checked);
              setShowCheckboxWarning(false);
            }}
          />
          <span>
            LuLaRoe only accepts missing item(s) claims within 30 days after the order has been received. 
            By checking this box, you confirm and acknowledge your missing item(s) claim is within 30 days 
            from the date of receipt.
          </span>
        </label>

        {showCheckboxWarning && (
          <div className={styles.checkboxWarning}>
            You must accept the terms to continue
          </div>
        )}
      </div>

      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default forwardRef(MissingItemForm); 