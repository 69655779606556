import React, { useState, useMemo, forwardRef, ForwardRefRenderFunction } from 'react';
import styles from './DamageItemForm.module.css';
import { DamageItem } from '../api/platform/integrations/salesforce/types/damage';
import { useData } from '../contexts/DataContext';
import { Product } from '../api/platform/integrations/salesforce/product/getActiveProducts';
import { sortSizes } from '../api/platform/integrations/salesforce/product/getActiveProducts';
import { logger } from '@/utils/logger';

interface Props {
  onChange: (items: DamageItem[]) => void;
  error?: string;
  onValidationChange?: (isValid: boolean) => void;
}

interface DamageFormRef {
  validateForm: () => boolean;
}

const DEFECT_TYPES = [
  'All Access Return',
  'Consumer Happiness Return',
  'Hole after wear',
  'Hole after purchase',
  'Pull in fabric',
  'Sewing damage',
  'Writing on fabric',
  'Transfer of dye',
  'Strong odor (please describe)',
  'Other (please describe)'
];

const COUNTRIES = [
  'Bangladesh',
  'Cambodia',
  'China',
  'Guatemala',
  'India',
  'Indonesia',
  'Japan',
  'Mexico',
  'Nicaragua',
  'Philippines',
  'South Korea',
  'USA',
  'Vietnam'
];

interface ProductWithSizes {
  name: string;
  sizes: string[];
  products: Product[];
}

const DamageItemForm: ForwardRefRenderFunction<DamageFormRef, Props> = ({ onChange, error, onValidationChange }, ref) => {
  const { products } = useData();
  const [selectedProductName, setSelectedProductName] = useState<string>('');
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [items, setItems] = useState<DamageItem[]>([]);
  const [currentItem, setCurrentItem] = useState<Partial<DamageItem>>({});
  const [termsAccepted, setTermsAccepted] = useState({
    certification: false,
    acknowledgment: false
  });
  const [showCheckboxWarning, setShowCheckboxWarning] = useState(false);

  // Group products by name and collect all available sizes
  const productGroups = useMemo(() => {
    const groups = new Map<string, ProductWithSizes>();
    
    products.forEach(product => {
      if (!groups.has(product.name)) {
        groups.set(product.name, {
          name: product.name,
          sizes: [],
          products: []
        });
      }
      
      const group = groups.get(product.name)!;
      if (!group.sizes.includes(product.size)) {
        group.sizes.push(product.size);
      }
      group.products.push(product);
    });

    // Sort sizes for each product
    groups.forEach(group => {
      group.sizes = sortSizes(group.sizes);
    });

    logger.debug('Product groups created:', { 
      metadata: Array.from(groups.values())
    });
    return Array.from(groups.values());
  }, [products]);

  // Get the available sizes for the selected product
  const availableSizes = useMemo(() => {
    const group = productGroups.find(g => g.name === selectedProductName);
    return group?.sizes || [];
  }, [selectedProductName, productGroups]);

  // Get the specific product based on name and size
  const getProductBySizeAndName = (name: string, size: string): Product | undefined => {
    const group = productGroups.find(g => g.name === name);
    return group?.products.find(p => p.size === size);
  };

  const handleProductChange = (productName: string) => {
    setSelectedProductName(productName);
    setSelectedSize('');
    setCurrentItem({
      ...currentItem,
      productName,
      size: '',
      sku: ''
    });
  };

  const handleSizeChange = (size: string) => {
    const product = getProductBySizeAndName(selectedProductName, size);
    setSelectedSize(size);
    if (product) {
      setCurrentItem({
        ...currentItem,
        size,
        sku: product.sku
      });
    }
  };

  const handleAddItem = () => {
    if (isItemValid()) {
      const newItem = {
        productName: currentItem.productName!,
        sku: currentItem.sku!,
        size: currentItem.size!,
        defectType: currentItem.defectType!,
        countryOfOrigin: currentItem.countryOfOrigin!,
        damageDescription: currentItem.damageDescription!
      } as DamageItem;

      logger.debug('Adding new damage item:', { metadata: newItem });
      
      const newItems = [...items, newItem];
      setItems(newItems);
      setCurrentItem({});
      setSelectedProductName('');
      setSelectedSize('');
      
      logger.debug('Current damage items list:', { metadata: newItems });
      onChange(newItems);
    }
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    onChange(newItems);
  };

  const isItemValid = () => {
    const descLength = currentItem.damageDescription?.length ?? 0;
    return currentItem.productName &&
           currentItem.size &&
           currentItem.defectType &&
           currentItem.countryOfOrigin &&
           descLength <= 250;
  };

  // Update validation whenever relevant state changes
  React.useEffect(() => {
    const isValid = isFormValid();
    onValidationChange?.(isValid);
    
    // Hide checkbox warning if both boxes are checked
    if (termsAccepted.certification && termsAccepted.acknowledgment) {
      setShowCheckboxWarning(false);
    }
  }, [items.length, termsAccepted, onValidationChange]);

  const isFormValid = () => {
    const isCheckboxesValid = termsAccepted.certification && termsAccepted.acknowledgment;
    return items.length >= 15 && isCheckboxesValid;
  };

  // Add this new method to expose to parent
  const validateForm = () => {
    const isCheckboxesValid = termsAccepted.certification && termsAccepted.acknowledgment;
    if (!isCheckboxesValid) {
      setShowCheckboxWarning(true);
    }
    return isFormValid();
  };

  // Expose validateForm method to parent component
  React.useImperativeHandle(ref, () => ({
    validateForm
  }));

  return (
    <div className={styles.damageForm}>
      <div className={styles.formFields}>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Product</label>
          <select
            value={selectedProductName}
            onChange={(e) => handleProductChange(e.target.value)}
            className={styles.select}
          >
            <option value="">Select Product</option>
            {productGroups.map(group => (
              <option key={group.name} value={group.name}>
                {group.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Size</label>
          <select
            value={selectedSize}
            onChange={(e) => handleSizeChange(e.target.value)}
            className={styles.select}
            disabled={!selectedProductName}
          >
            <option value="">Select Size</option>
            {availableSizes.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Type of Defect</label>
          <select
            value={currentItem.defectType || ''}
            onChange={(e) => setCurrentItem({...currentItem, defectType: e.target.value})}
            className={styles.select}
            disabled={!selectedSize}
          >
            <option value="">Select Defect Type</option>
            {DEFECT_TYPES.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Country of Origin</label>
          <select
            value={currentItem.countryOfOrigin || ''}
            onChange={(e) => setCurrentItem({...currentItem, countryOfOrigin: e.target.value})}
            className={styles.select}
            disabled={!selectedSize}
          >
            <option value="">Select Country</option>
            {COUNTRIES.map(country => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Description of Damages</label>
          <textarea
            value={currentItem.damageDescription || ''}
            onChange={(e) => setCurrentItem({...currentItem, damageDescription: e.target.value})}
            maxLength={250}
            className={styles.textarea}
            placeholder="Describe the damage (max 250 characters)"
            disabled={!selectedSize}
          />
        </div>

        <button 
          onClick={handleAddItem}
          disabled={!isItemValid()}
          className={styles.addButton}
          type="button"
        >
          Add Item
        </button>
      </div>

      {items.length > 0 && (
        <div className={styles.itemsTable}>
          <h3>Added Items ({items.length})</h3>
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Size</th>
                <th>Defect Type</th>
                <th>Country</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.productName}</td>
                  <td>{item.size}</td>
                  <td>{item.defectType}</td>
                  <td>{item.countryOfOrigin}</td>
                  <td>
                    <button
                      onClick={() => handleRemoveItem(index)}
                      className={styles.removeButton}
                      type="button"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className={styles.terms}>
        <label className={styles.checkbox}>
          <input
            type="checkbox"
            checked={termsAccepted.certification}
            onChange={(e) => {
              setTermsAccepted({
                ...termsAccepted,
                certification: e.target.checked
              });
              setShowCheckboxWarning(false);
            }}
          />
          <span>I certify the items attached to this submission are unique damage claims that have not been submitted before. The information listed here is true and accurate.</span>
        </label>

        <label className={styles.checkbox}>
          <input
            type="checkbox"
            checked={termsAccepted.acknowledgment}
            onChange={(e) => {
              setTermsAccepted({
                ...termsAccepted,
                acknowledgment: e.target.checked
              });
              setShowCheckboxWarning(false);
            }}
          />
          <span>By clicking this box you acknowledge and understand you are only authorized to send back the damaged items listed on this form. Any additional items sent not included on the form will be forfeited and will not be replaced.</span>
        </label>

        {showCheckboxWarning && (
          <div className={styles.checkboxWarning}>
            You must check both boxes to continue
          </div>
        )}
      </div>

      {error && <div className={styles.error}>{error}</div>}
      
      {items.length < 15 && (
        <div className={styles.warning}>
          Minimum of 15 items required ({15 - items.length} more needed)
        </div>
      )}
    </div>
  );
};

// Convert to forwardRef to expose methods to parent
export default forwardRef(DamageItemForm); 