import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Support.module.css';
import Layout from '../components/Layout';
import { CreateCaseRequest, createCase } from '../api/platform/integrations/salesforce/case/createCase';
import { caseTypes } from '../config/caseTypes';
import CharacterLimitedTextField from '../components/CharacterLimitedTextField';
import { Helmet } from 'react-helmet-async';
import { APP_TITLE_SUFFIX } from '../constants';
import DamageItemForm from '../components/DamageItemForm';
import { DamageItem } from '../api/platform/integrations/salesforce/types/damage';
import Button from '../shared/components/Button';
import MissingItemForm from '../components/MissingItemForm';
import { MissingItem } from '../api/platform/integrations/salesforce/types/missing';

const Support = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('');
  const [selectedSubType, setSelectedSubType] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [description, setDescription] = useState('');
  const [fieldValues, setFieldValues] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [damageItems, setDamageItems] = useState<DamageItem[]>([]);
  const [damageItemsError, setDamageItemsError] = useState<string | undefined>(undefined);
  const [isDamageFormValid, setIsDamageFormValid] = useState(false);
  const damageFormRef = useRef<{ validateForm: () => boolean }>(null);
  const [missingItems, setMissingItems] = useState<MissingItem[]>([]);
  const [missingItemsError, setMissingItemsError] = useState<string | undefined>(undefined);
  const [isMissingFormValid, setIsMissingFormValid] = useState(false);
  const missingFormRef = useRef<{ validateForm: () => boolean }>(null);

  const selectedTypeData = caseTypes.find(t => t.value === selectedType);
  const selectedSubTypeData = selectedTypeData?.subTypes.find(st => st.value === selectedSubType);

  const handleFieldChange = (id: string, value: string) => {
    if (id === 'wholesaleOrder' && value.length > 10) {
      return;
    }

    if (id === 'numberOfBoxes') {
      const numValue = parseInt(value);
      if (value !== '' && (!Number.isInteger(numValue) || numValue < 1)) {
        return;
      }
    }

    setFieldValues(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (isDamageCase) {
      const isValid = damageFormRef.current?.validateForm();
      if (!isValid) {
        return;
      }
    }

    if (isMissingItemCase) {
      const isValid = missingFormRef.current?.validateForm();
      if (!isValid) {
        return;
      }
    }

    setIsSubmitting(true);
    setError(null);
    setDamageItemsError(undefined);
    setMissingItemsError(undefined);

    try {
      const caseData: CreateCaseRequest = {
        type: selectedType,
        subType: selectedSubType,
        subCategory: selectedSubCategory,
        description,
        additionalFields: {
          ...fieldValues,
          ...(isDamageCase ? { damageItems } : {}),
          ...(isMissingItemCase ? { missingItems } : {})
        }
      };

      const caseDetail = await createCase(caseData);
      
      navigate(`/cases/${caseDetail.reference}`, {
        state: { caseDetail }
      });
      
    } catch (err) {
      console.error('Error creating case:', err);
      const error = err as Error;
      if (error.message.includes('damage items')) {
        setDamageItemsError(error.message);
      } else if (error.message.includes('missing items')) {
        setMissingItemsError(error.message);
      } else {
        setError(error instanceof Error ? error.message : 'Failed to create case');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isDamageCase = selectedType === 'Apparel Services' && selectedSubType === 'Damage Cases';
  const isMissingItemCase = selectedType === 'Warehouse & Shipping' && selectedSubType === 'Missing Items';

  return (
    <Layout>
      <Helmet>
        <title>New Case | {APP_TITLE_SUFFIX}</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.content}>
          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-md mb-4">
              {error}
            </div>
          )}
          
          <div className={styles.textContainer}>
            <h1 className={styles.header}>Support</h1>
            <p className={styles.helpText}>
              Need help? Submit your request below.
            </p>
          </div>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="type" className={styles.formLabel}>How can we help you?</label>
              <select
                id="type"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                  setSelectedSubType('');
                  setSelectedSubCategory('');
                  setFieldValues({});
                }}
                required
                className={styles.select}
              >
                <option value="">Select an option</option>
                {caseTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            {selectedType && (
              <div className={styles.formGroup}>
                <label htmlFor="subType" className={styles.formLabel}>Sub-Type</label>
                <select
                  id="subType"
                  value={selectedSubType}
                  onChange={(e) => {
                    setSelectedSubType(e.target.value);
                    setSelectedSubCategory('');
                    setFieldValues({});
                  }}
                  required
                  className={styles.select}
                >
                  <option value="">Select a sub-type</option>
                  {selectedTypeData?.subTypes.map(subType => (
                    <option key={subType.value} value={subType.value}>
                      {subType.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {selectedSubTypeData && selectedSubTypeData.fields && selectedSubTypeData.fields.length > 0 && (
              selectedSubTypeData.fields.map(field => (
                <div key={field.id} className={styles.formGroup}>
                  <label htmlFor={field.id} className={styles.formLabel}>{field.label}</label>
                  {field.type === 'select' ? (
                    <select
                      id={field.id}
                      value={fieldValues[field.id] || ''}
                      onChange={(e) => handleFieldChange(field.id, e.target.value)}
                      required={field.required}
                      className={styles.select}
                    >
                      <option value="">{field.placeholder}</option>
                      {field.options?.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      id={field.id}
                      placeholder={field.placeholder}
                      value={fieldValues[field.id] || ''}
                      onChange={(e) => handleFieldChange(field.id, e.target.value)}
                      required={field.required}
                      className={styles.input}
                    />
                  )}
                </div>
              ))
            )}

            {selectedSubTypeData?.subCategories && (
              <div className={styles.formGroup}>
                <label htmlFor="subCategory" className={styles.formLabel}>Category</label>
                <select
                  id="subCategory"
                  value={selectedSubCategory}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                  required
                  className={styles.select}
                >
                  <option value="">Select a category</option>
                  {selectedSubTypeData.subCategories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {!isDamageCase && (
              <div className={styles.formGroup}>
                <CharacterLimitedTextField
                  id="description"
                  value={description}
                  onChange={setDescription}
                  maxLength={2500}
                  required
                  label="Please describe the nature of your inquiry:"
                  className={styles.textarea}
                  labelClassName={styles.formLabel}
                  minHeight="150px"
                  resizable={true}
                  width="100%"
                />
              </div>
            )}

            {isDamageCase && (
              <DamageItemForm
                ref={damageFormRef}
                onChange={setDamageItems}
                error={damageItemsError}
                onValidationChange={setIsDamageFormValid}
              />
            )}

            {isMissingItemCase && (
              <MissingItemForm
                ref={missingFormRef}
                onChange={(items) => setMissingItems(items)}
                error={missingItemsError}
                onValidationChange={setIsMissingFormValid}
              />
            )}

            <Button 
              type="submit" 
              variant="submit"
              size="submit"
              fullWidth
              isLoading={isSubmitting}
              disabled={
                isSubmitting || 
                (isDamageCase && !isDamageFormValid) ||
                (isMissingItemCase && !isMissingFormValid)
              }
            >
              SUBMIT A CASE
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
