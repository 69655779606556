export interface CaseField {
  id: string;
  label: string;
  placeholder: string;
  type: 'text' | 'select' | 'textarea';
  required: boolean;
  options?: string[];
}

export interface CaseSubType {
  value: string;
  label: string;
  fields: CaseField[];
  subCategories?: string[];
}

export interface CaseType {
  value: string;
  label: string;
  subTypes: CaseSubType[];
}

export const caseTypes: CaseType[] = [
  {
    value: 'Accounting',
    label: 'Accounting',
    subTypes: [
      {
        value: 'End of Year Docs',
        label: 'End of Year Documents',
        subCategories: [
          'End of Year Report Inquiry',
          '1099 Inquiry',
          'General End of Year Doc Questions'
        ],
        fields: []
      },
      {
        value: 'Sales Tax',
        label: 'Sales Tax',
        subCategories: [
          'Sales Tax Account # Requests',
          'General Sales Tax Questions'
        ],
        fields: []
      },
      {
        value: 'Comp Calculations',
        label: 'Compensation Calculations',
        subCategories: [
          'Roll Up / Compression',
          'Field Reports',
          'Comp Check Amount Verification',
          'General Comp Calculations Questions'
        ],
        fields: []
      },
      {
        value: 'Refund / Overcharge / Payment Issue',
        label: 'Refund / Overcharge / Payment Issue',
        fields: []
      },
      {
        value: 'Collections',
        label: 'Collections',
        subCategories: ['General Collections Questions'],
        fields: []
      },
      {
        value: 'Business Entity',
        label: 'Business Entity',
        subCategories: [
          'Converting Business',
          'Business Entity General Questions'
        ],
        fields: []
      },
      {
        value: 'Check Inquiry',
        label: 'Check Inquiry',
        fields: []
      },
      {
        value: 'General Accounting',
        label: 'General Accounting',
        fields: []
      }
    ]
  },
  {
    value: 'Apparel Services',
    label: 'Apparel Services',
    subTypes: [
      {
        value: 'General Apparel',
        label: 'General Apparel',
        fields: []
      },
      {
        value: 'Damage Cases',
        label: 'Damage Cases',
        fields: []
      }
    ]
  },
  {
    value: 'Warehouse & Shipping',
    label: 'Warehouse & Shipping',
    subTypes: [
      {
        value: 'General Orders',
        label: 'General Orders',
        fields: []
      },
      {
        value: 'Missing Items',
        label: 'Missing Items',
        fields: [
          {
            id: 'orderNumber',
            label: 'Order Number',
            placeholder: 'Enter order number',
            type: 'text',
            required: true
          },
          {
            id: 'numberOfBoxes',
            label: 'Number of Boxes',
            placeholder: 'Enter number of boxes',
            type: 'text',
            required: true
          },
          {
            id: 'preferredCorrectiveAction',
            label: 'Preferred Corrective Action',
            placeholder: 'Select preferred corrective action',
            type: 'select',
            required: true,
            options: [
              'Receive Credit on Account',
              'Ship Replacement Products'
            ]
          }
        ]
      }
    ]
  },
  {
    value: 'System Support',
    label: 'System Support',
    subTypes: [
      {
        value: 'BUILD',
        label: 'BUILD',
        subCategories: [
          'Retailer Dashboard',
          'Payments',
          'My Orders',
          'Shopping Cart',
          'Other Build Questions'
        ],
        fields: []
      },
      {
        value: 'All Access',
        label: 'All Access',
        fields: []
      },
      {
        value: 'BLESS',
        label: 'BLESS',
        subCategories: [
          'Online Boutique',
          'Web Portal',
          'Bless App'
        ],
        fields: []
      },
      {
        value: 'Retailer Map',
        label: 'Retailer Map',
        fields: []
      },
      {
        value: 'Merchant Application',
        label: 'Merchant Application',
        fields: []
      },
      {
        value: 'HOME',
        label: 'HOME',
        fields: []
      },
      {
        value: 'General Systems Support',
        label: 'General Systems Support',
        fields: []
      }
    ]
  },
  {
    value: 'Business Support',
    label: 'Business Support',
    subTypes: [
      {
        value: 'T.E.A.M. Management',
        label: 'T.E.A.M. Management',
        subCategories: [
          'Military Deployment Leave',
          'Business Transfers',
          'Sponsorship Changes',
          'Leave of Absence',
          'Maternity',
          'Partnership Splits',
          'Name Change Request',
          'Other T.E.A.M. Management Questions'
        ],
        fields: []
      },
      {
        value: 'Rewards & Recognition',
        label: 'Rewards & Recognition',
        subCategories: [
          'Cruise Questions'
        ],
        fields: []
      },
      {
        value: 'Marketing & Branding',
        label: 'Marketing & Branding',
        subCategories: [
          'Marketing Compliance',
          'Back Office Assets',
          'Media Inquiries',
          'Branding',
          'Collections Launches / New Product',
          'Other Marketing & Branding Questions'
        ],
        fields: []
      },
      {
        value: 'Fundraisers',
        label: 'Fundraisers',
        subCategories: [
          'Other Fundraiser Questions'
        ],
        fields: []
      },
      {
        value: 'Compliance',
        label: 'Compliance',
        subCategories: [
          'Policies & Procedures',
          'LuLaRoe Culture',
          'Other Compliance Questions'
        ],
        fields: []
      },
      {
        value: 'Onboarding',
        label: 'Onboarding',
        subCategories: [
          'Questions about your T.E.A.M.',
          'Other Onboarding Questions'
        ],
        fields: []
      }
    ]
  },
  {
    value: 'Events',
    label: 'Events',
    subTypes: [
      {
        value: 'DREAM',
        label: 'DREAM',
        subCategories: [
          'Registration',
          'Travel / Lodging',
          'Time / Date',
          'Location',
          'Guest Requirements',
          'Other'
        ],
        fields: []
      },
      {
        value: 'VISION',
        label: 'VISION',
        subCategories: [
          'Registration',
          'Travel / Lodging',
          'Time / Date',
          'Location',
          'Guest Requirements',
          'Other'
        ],
        fields: []
      },
      {
        value: 'Leadership',
        label: 'Leadership',
        subCategories: [
          'Registration',
          'Travel / Lodging',
          'Time / Date',
          'Location',
          'Guest Requirements',
          'Other'
        ],
        fields: []
      },
      {
        value: 'Tours',
        label: 'Tours',
        subCategories: [
          'Registration',
          'Travel / Lodging',
          'Time / Date',
          'Location',
          'Guest Requirements',
          'Other'
        ],
        fields: []
      }
    ]
  }
]; 